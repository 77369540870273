var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ name:'login' }}},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" FiqOn ")]),_c('b-img',{staticClass:"fiqon-logo",class:_vm.logoUrl ? '' : 'light-logo',attrs:{"fluid":"","src":_vm.logoImg,"alt":"Login V2"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"xl":"8","lg":"6"}},[_c('div',{staticClass:"dark-light-icon bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"}),_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('Animation',{staticStyle:{"max-width":"82%"},attrs:{"animationType":"register"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"xl":"4","lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('login.create_account.be_an_user'))),_c('span',{staticClass:"brand-name"},[_vm._v("FiqOn")]),_c('b-img',{staticClass:"fiqon-logo-welcome",class:_vm.logoUrl ? '' : 'light-logo',attrs:{"fluid":"","src":_vm.logoImg,"alt":"Login Logo"}})],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('login.create_account.do_transmission'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.fullname'),"label-for":"register-fullname"}},[_c('validation-provider',{attrs:{"name":"fullname","vid":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false:null,"placeholder":"Fullname"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.username'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"email@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.password'),"label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.phone'),"label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false:null,"placeholder":"(00) 0 0000-0000"},on:{"input":_vm.phoneMask},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('login.create_account.privacy_policy.agree'))+" "),_c('b-link',{staticClass:"text-primary",attrs:{"to":{name:'privacy-policy-use-terms'}}},[_vm._v(_vm._s(_vm.$t('login.create_account.privacy_policy.link')))])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('login.create_account.sing_up'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.create_account.already_account'))+" ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.create_account.enter_account'))+" ")])])],1),_c('div',{staticClass:"divider my-2"},[_c('b-link',{staticClass:"divider-text"},[_vm._v(" fique.online ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link :to="{ name:'login' }" class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          FiqOn
        </h2>
        <b-img
          fluid
          class="fiqon-logo" 
          :class="logoUrl ? '' : 'light-logo' "
          :src="logoImg"
          alt="Login V2"
        />
      </b-link>
      <!-- /Brand logo-->

      
      <!-- Left Text-->
      <b-col
        xl="8"
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="dark-light-icon bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
          <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        </div>
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <Animation animationType="register" style="max-width: 82%;"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        xl="4"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('login.create_account.be_an_user') }}<span class="brand-name">FiqOn</span>
            <b-img
              fluid
              class="fiqon-logo-welcome" 
              :class="logoUrl ? '' : 'light-logo'"
              :src="logoImg"
              alt="Login Logo"
            />
          </b-card-title>
          <b-card-text class="mb-2">
             {{ $t('login.create_account.do_transmission') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- fullname -->
              <b-form-group
                :label="$t('login.create_account.form.fullname')"
                label-for="register-fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fullname"
                  vid="Fullname"
                  rules="required"
                >
                  <b-form-input
                    id="register-fullname"
                    v-model="fullname"
                    name="register-fullname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Fullname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                :label="$t('login.create_account.form.username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('login.create_account.form.email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="email@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="$t('login.create_account.form.password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group
                :label="$t('login.create_account.form.phone')"
                label-for="register-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="register-phone"
                    v-model="phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="(00) 0 0000-0000"
                    @input="phoneMask"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('login.create_account.privacy_policy.agree')}}
                  <b-link class="text-primary" :to="{name:'privacy-policy-use-terms'}">{{ $t('login.create_account.privacy_policy.link')}}</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
              >
                {{ $t('login.create_account.sing_up')}}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span> {{ $t('login.create_account.already_account') }} </span>
            <b-link :to="{name:'login'}">
              <span> {{ $t('login.create_account.enter_account') }} </span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <b-link class="divider-text">
              fique.online
            </b-link>
          </div>

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'
import Animation from '/src/layouts/components/Animation.vue'
import { makeToast } from '@/layouts/components/Popups'
import custom from "@/custom";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Animation,
    DarkToggler,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      fullname: '',
      username: '',
      userEmail: '',
      phone: '',
      password: '',
      logoImg: require('@/assets/images/logo/logo.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return false
      }
      return true
    },
    /* formatedPhone(){

    } */
  },
  methods: {
    register() {
      console.log('status', this.status)
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          // useJwt.register({
          //   username: this.username,
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)
          //     this.$router.push('/')
          //   })
          //   .catch(error => {
          //     this.$refs.registerForm.setErrors(error.response.data.error)
          //   })

          if (this.status == true) {
            this.$store.dispatch('userRegistration', 
              {
                username: this.username,
                password: this.password,
                email   : this.userEmail,
                phone   : this.phone,
                fullname: this.fullname,  
              }
            )
            .then(response => {
              console.log(response) 
  
              if(response.status == 200){
          
                this.$router.push({ name:'verify-email' }).then(() => {
                  makeToast(custom.successMessages.register)            
                })
              }else{
                makeToast(custom.errorMessages[response.data.code])
              }
            }).catch(error => {
              console.log('opa', error)
            })
          }else{
            makeToast(custom.errorMessages['agreeWithTerms'])
          }
        }
      })
    },
    async phoneMask(){
      /* let value = this.phone
      if (value.length <= 3){
        var re = new RegExp("^([0-9]{"+value.length+"})$");
        console.log('re 1 ->>', re)
        //return value = value.replace(re, '($1)');
      } 
      if (value.length > 3 && value.length <= 6){
        var re = new RegExp("^([0-9]{3})([0-9]{"+ (value.length - 3)+"})$");
        console.log('re 2 ->>', re)
        //return value = value.replace(re, '($1)-$2');
      }
      if (value.length > 6 && value.length <= 10){
        var re = new RegExp("^([0-9]{3})([0-9]{3})([0-9]{"+(value.length-6)+"})$");
        console.log('re 3 ->>', re)
        //return value = value.replace(re, '($1)-$2-$3');
      } */
      //return value;

      let value = await this.phone
      value = await value.replace(/\D/g,"")
      if (value.length <= 2){
        var re = new RegExp("^([0-9]{"+value.length+"})$");
        value = value.replace(re, '($1)');
        this.phone = value
      } 
      if (value.length > 2 && value.length <= 6){
        var re = new RegExp("^([0-9]{2})([0-9]{"+ (value.length - 2)+"})$");
        value = value.replace(re, '($1) $2');
        this.phone = value
      }
      if (value.length > 6 && value.length <= 10){
        var re = new RegExp("^([0-9]{2})([0-9]{4})([0-9]{"+(value.length-6)+"})$");
        value = value.replace(re, '($1) $2-$3');
        this.phone = value
      }
      if (value.length >= 11) {
        var re = new RegExp("^([0-9]{2})([0-9]{1})([0-9]{4})([0-9]{"+(value.length-7)+"})$");
        value = value.replace(re, '($1) $2 $3-$4')
        this.phone = value
      }
      this.phone = value
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .fiqon-logo-welcome{
    height: 17px;
  }
  .light-logo{
    filter: brightness(0) invert(1);
  }
  .brand-name{
    color: $primary;
  }
  .dark-light-icon{
    position:absolute;
    right:20px;
    top  :10px;
  }
</style>
